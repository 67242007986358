<template>
  <div>
    <b-overlay
        :show="!pageLoaded"
    >
  <b-card
      class="client-create-wrapper"
  >

    <b-row>
      <b-col
        cols="4" class="mt-50">
        <h2>Risk: {{ client.totalRisk }}
          <b-badge v-if="client.thresholdRating === 'low'" variant="success">Low Risk</b-badge>
          <b-badge v-if="client.thresholdRating === 'medium'" variant="warning">Medium Risk</b-badge>
          <b-badge v-if="client.thresholdRating === 'high'" variant="danger">High Risk</b-badge>
          <b-badge v-if="client.thresholdRating === 'very_high'" class="very-high-risk">Very High Risk</b-badge>
        </h2>
      </b-col>
      <b-col
          cols="4" class="mt-50 text-center">
        <h2 v-if="client.isComplete"><feather-icon icon="CheckIcon" size="28" class="text-success" /> Complete</h2>
        <h2 v-if="!client.isComplete"><feather-icon icon="AlertTriangleIcon" size="28" class="text-warning" /> Incomplete</h2>
      </b-col>
      <b-col
          cols="4"
          class="mt-50 text-right"
      >
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :href="'/client/'+client.id+'/edit'"
        >
          Edit
        </b-button>
      </b-col>
    </b-row>

    <!--form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="12">
          <h3>General Information</h3>
        </b-col>
        <b-col md="6">
          <p><strong>Name:</strong> {{ client.name }}</p>
        </b-col>
        <b-col md="6">
          <p><strong>Client Structure:</strong> {{ client.structure }}</p>
          <p v-if="client.registrationNumber"><strong>Registration Number:</strong> {{ client.registrationNumber }}</p>
        </b-col>
        <b-col md="12">
          <client-owner-list v-if="client.id" :client-id="client.id"></client-owner-list>
          <client-secondary-owner-list v-if="client.id" :client-id="client.id"></client-secondary-owner-list>
        </b-col>
        <b-col md="12" v-for="(category,index) in riskQuestionCategories" :key="index" class="mt-2 mb-2">
          <h3>{{ category }}</h3>
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th>Question</th>
              <th class="text-center">Answer</th>
              <th class="text-center">File</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(riskQuestion,index) in riskQuestions" :key="index" v-if="riskQuestion.category === category">
              <td width="70%">{{ riskQuestion.question }}</td>
              <td class="text-center" width="15%">
                <div v-if="form[riskQuestion.questionLabel]">
                  <div v-if="form[riskQuestion.questionLabel].questionType === 'select'">
                    {{ form[riskQuestion.questionLabel].answer }}
                  </div>
                  <div v-if="form[riskQuestion.questionLabel].questionType === 'text' || form[riskQuestion.questionLabel].questionType === 'date'">
                    {{ form[riskQuestion.questionLabel].input }}
                  </div>
                  <div v-if="form[riskQuestion.questionLabel].questionType === 'checkbox' || form[riskQuestion.questionLabel].questionType === 'multi_select'">
                    <p v-for="selection in form[riskQuestion.questionLabel].selections">{{ selection }}</p>
                  </div>
                </div>
              </td>

              <td class="text-center" width="15%">
                <div v-if="form[riskQuestion.questionLabel].fileUrl">
                  <feather-icon class="mr-1 cursor-pointer" icon="FileTextIcon" size="20" @click="downloadFile(form[riskQuestion.questionLabel].fileUrl)" />
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <!--            <p v-if="form[riskQuestion.questionLabel].file">[ File link ]</p>-->
        </b-col>
      </b-row>
    </b-form>
    <!--form-->
  </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BBadge,
  BInputGroupPrepend,
  BInputGroup,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.gb'
import clientService from "@core/services/client/useClient";
import riskService from "@core/services/risk/useRisk";
import OwnerList from "@/views/pages/owner/OwnerList";
import ClientOwnerList from "@/views/pages/client-owner/ClientOwnerList";
import ClientSecondaryOwnerList from "@/views/pages/client-secondary-owner/ClientSecondaryOwnerList";
import {$themeConfig} from "@themeConfig";

export default {
  components: {
    OwnerList,
    BCard,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BBadge,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormFile,
    BInputGroupPrepend,
    BFormCheckbox,
    BOverlay,
    ClientOwnerList,
    ClientSecondaryOwnerList,
    vSelect,
    Cleave,
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.client.id = this.$route.params.id;
    this.getRiskQuestions();
  },
  data() {
    return {
      client: {
        id: 0,
        name: "",
        structure: "",
        registrationNumber: "",
        practiceId: 0,
        isComplete: false,
        totalRisk: 0,
        answers: [],
      },
      form: {},
      riskQuestionCategories: [],
      riskQuestions: [],
      structureTypes: ['Sole Trader', 'Partnership', 'Ltd Company', 'Limited Partnership', 'Limited Liability Partnership', 'Other'],
      clientRisk: 0,
      pageLoaded: false
    }
  },
  methods: {
    getClient() {
      if ( this.$route.params.id ){
        clientService.getClient({
          id: this.$route.params.id
        }).then(response => {
          this.client = response.data;
          let that = this;

          if ( this.client.answers.length > 0 ){
            this.client.answers.forEach(function (answer) {
              that.$set(that.form, answer.questionLabel, answer)
            });
          }

          this.recalculateRisk();
          this.pageLoaded = true;
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      }else{
        this.pageLoaded = true;
      }
    },
    getRiskQuestions() {
      riskService.getRiskQuestions({
        'system_form_id': 2
      }).then(response => {
        // let practice = this.practice;
        let that = this;
        this.riskQuestions = response.data.questions;
        this.riskQuestionCategories = response.data.categories;
        this.riskQuestions.forEach(function (question) {
          that.$set(that.form, question.questionLabel, {riskFactor: 0})
        });

        this.getClient();
      }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });

    },
    downloadFile(url){
      window.open(url,'_blank');
    },
    recalculateRisk() {
      this.clientRisk = riskService.recalculateRisk(this.client.answers);
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
